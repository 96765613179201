@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1825px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1637px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1057px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1824px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1636px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1056px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1056px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1057px) and (max-width: 1636px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1637px) and (max-width: 1824px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/* CSS Document */
/*====================================================================

	scroll animation

=====================================================================*/
/* fade-in */
.fade-in.trigger--off,
.trigger--off .fade-in {
  opacity: 0;
}

.fade-in.trigger--on,
.trigger--on .fade-in {
  transition: all 0.8s cubic-bezier(0.2, 0.24, 0.7, 0.69);
  opacity: 1;
}

.fade-up.trigger--off,
.trigger--off .fade-up {
  opacity: 0;
  transform: translateY(70px);
}

.fade-up.trigger--on,
.trigger--on .fade-up {
  transition: all 0.6s cubic-bezier(0.43, 0.03, 0.7, 0.97);
  opacity: 1;
  transform: translateY(0);
}

.fade-left.trigger--off,
.trigger--off .fade-left {
  opacity: 0;
  transform: translatex(-100px);
}

.fade-left.trigger--on,
.trigger--on .fade-left {
  transition: all 0.7s cubic-bezier(0.16, 0.25, 0.7, 0.97);
  opacity: 1;
  transform: translatex(0);
}

.fade-right.trigger--off,
.trigger--off .fade-right {
  opacity: 0;
  transform: translatex(100px);
}

.fade-right.trigger--on,
.trigger--on .fade-right {
  transition: all 0.7s cubic-bezier(0.16, 0.25, 0.7, 0.97);
  opacity: 1;
  transform: translatex(0);
}
